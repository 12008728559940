import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It’s weird isn’t it? How in books or in the movies they say ‘a shot rang out’. But there’s nothing musical about a bang. It’s just a loud sound. But in that second, that very second, you lose your senses. Your mind blanks out, you prepare what little time you have left, for possible agony. Or death.`}</p>
    <p>{`I was lucky enough to survive a shot straight to the face. Or unlucky. I’ve been in this damn hospital bed for months, or was it years? Maybe it was weeks. I lost my sense of time. So it’s just me. Me and my needles and my IV fluids and my bandages.`}</p>
    <p>{`It’s been like this for a while. Suffered two other shots. I was paralyzed from the waist down, and can’t talk. My entire left eye is completely gone, and my right eye is going blind as well. I remember when I first saw my face. It was like something out of a nightmare. Even the doctors had concerned looks when they were bringing the mirror. My left eye was white, I had nasty, puckered stitches running down the side of my face. My mouth is now nothing but a shrunken, shriveled rag. Whenever I sit up, a side of my face begins sagging and the pain is indescribable.`}</p>
    <p>{`My organs are beginning to fail. I know it. I’m trying to fight. I am. But my heart is weak, and my liver is failing. My lungs have turned to rust. I think back to my modelling days. I was thin, young and full of life. Every guy I passed was bewitched by my figure, my smile. I was confident, radiant with a bright vision for a future. Now here I am, sentenced to live a hopeless, monotone life in the ward.`}</p>
    <p>{`The monitor measuring my heart rate started beeping furiously. I began feeling lightheaded. Figures rushed into the room.`}</p>
    <p>{`‘Kamila! Can you hear me? Stay with us!’`}</p>
    <p>{`The voice seemed far away, unimportant. I was fading.`}</p>
    <p>{`I can’t do this anymore. And I don’t have to. No more surgeries. No more injections. No more chaos. Peace. I could leave this. This is no place to live a life. Believe me, I tried to fight as hard as I could.`}</p>
    <p>{`My parents would be devastated, my friends in tears. What about my reputation, my standing? I worked so hard for it… Everything would be gone.`}</p>
    <p>{`But that’s for people with futures, with dreams. And I was stripped of it.`}</p>
    <p>{`Not much time to decide now.`}</p>
    <p>{`The voices became slower and quieter, my heartbeat slowed, my eyes closed. My senses disappeared to nothing. I fell, and fell, and fell. Darkness ensued.`}</p>
    <p>{`“Time of death – 17:56”`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      